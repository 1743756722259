import { get, put, post, remove } from '@/helpers/http';

export function getNeighborhoodById(id) {
  return get(`/neighborhoods/${id}`);
}

export function updateNeighborhoodInformation(neighborhood) {
  return put(`/neighborhoods/${neighborhood.id}`, neighborhood);
}

export function createNewNeighborhood(neighborhood) {
  return post('/neighborhoods/', neighborhood);
}

export function removeNeighborhood(neighborhood) {
  return remove(`/neighborhoods/${neighborhood.id}`);
}

export function fetchNeighborhoods(pagination = {}) {
  return get('/neighborhoods', { ...pagination });
}

export function fetchNeighborhoodsByCity(idCity) {
  return post('/neighborhoods-city', { city_id: idCity });
}
