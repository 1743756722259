<template>
  <getecma-single-content-layout>
    <template #content>
      <getecma-breadcrumb :items="itemsBreadcrumb" class="mt--xl " />
      <div class="d--flex justify-content--space-between align-items--center">
        <getecma-header class="mt--md">Bairros</getecma-header>
        <div class="d--flex align-items--center" @keyup.enter="onSearchInput">
          <el-input
            v-model="searchQuery"
            placeholder="Buscar bairro"
            class="input-with-select"
            clearable
            @clear="onClearSearch">
            <el-button slot="append" icon="el-icon-search" @click="onSearchInput"></el-button>
          </el-input>
          <getecma-button v-if="isAdmin" style="margin-left: 5%;" @click="onCreateNeighborhood">
            Adicionar Bairro
          </getecma-button>
        </div>
      </div>
      <div class="d-flex scroll mb--lg justify-content-center" :style="isMinLayout() ? 'height: 50px;':''" @scroll="infinityScroll($event, neighborhoods)">
        <div v-if="neighborhoods.data.length" class="w-100">
          <div
            v-for="neighborhood in neighborhoods.data"
            :key="neighborhood.id"
            class="w-100 mb--md">
            <getecma-neighborhood-card
              :neighborhood="neighborhood" />
          </div>
        </div>
        <getecma-empty-box v-else description="Nenhum bairro encontrado" />
      </div>
    </template>
  </getecma-single-content-layout>
</template>

<script>
import { goToCreateNeighborhood } from '@/modules/neighborhood/neighborhood.routes';
import { fetchNeighborhoods } from '@/modules/neighborhood/neighborhood.service';
import { getters } from '@/modules/user/user.store';
import { USERS_ROLES } from '@/modules/user/user.constants';
import { toastError } from '@/services/toastService';
import { infinityScroll } from '@/helpers/scroll/infinity';
import { isMinLayout } from '@/helpers/layout/layout';

import GetecmaNeighborhoodCard from '@/modules/neighborhood/components/NeighborhoodCard.vue';
import GetecmaSingleContentLayout from '@/layouts/SingleContentLayout.vue';
import GetecmaBreadcrumb from '@/components/breadcrumb/Breadcrumb.vue';

export default {
  name: 'GetecmaMySpaceNeighborhoods',
  components: {
    GetecmaNeighborhoodCard,
    GetecmaSingleContentLayout,
    GetecmaBreadcrumb,
  },
  data() {
    return {
      itemsBreadcrumb: [
        { name: 'Dashboard', path: '/' },
        { name: 'Bairros', path: this.$route.path },
      ],
      neighborhoods: { data: [], pagination: { page: 1, limit: 20, lastPage: 0 } },
      searchQuery: '',
    };
  },
  computed: {
    isAdmin() {
      return getters.getUser().permission === USERS_ROLES.ADMIN;
    },
  },
  mounted() {
    this.fetchNeighborhoods();
  },
  methods: {
    isMinLayout,
    fetchNeighborhoods() {
      this.neighborhoods.loading = true;
      fetchNeighborhoods({ limit: this.neighborhoods.pagination.limit, page: this.neighborhoods.pagination.page, search: this.searchQuery })
        .then(({ neighborhoods, total_neighborhoods: total }) => {
          if (this.searchQuery === '') {
            this.neighborhoods.data.push(...neighborhoods);
          } else {
            this.neighborhoods.data = neighborhoods;
          }
          Object.assign(this.neighborhoods.pagination, { total: parseInt(total), lastPage: parseInt((total / this.neighborhoods.pagination.limit) + 1) });
        })
        .catch(() => toastError('Erro ao obter bairros.'))
        .finally(() => { this.neighborhoods.loading = false; });
    },
    onCreateNeighborhood() {
      goToCreateNeighborhood(this.$router);
    },
    infinityScroll(event, content) {
      infinityScroll(event, content)
        .then(() => {
          this.fetchNeighborhoods();
        })
        .catch(() => {});
    },
    onSearchInput() {
      this.neighborhoods.pagination.page = 1;
      this.neighborhoods.pagination.lastPage = 0;
      this.neighborhoods.data = [];
      this.fetchNeighborhoods();
    },
    onClearSearch() {
      this.searchQuery = '';
      this.onSearchInput();
    },
  },
};
</script>
