import * as neighborhoodResource from '@/modules/neighborhood/neighborhood.resource';

export function fetchNeighborhoods(pagination = {}) {
  return new Promise((resolve, reject) =>
    neighborhoodResource
      .fetchNeighborhoods(pagination)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function fetchNeighborhoodsByCity(idCity) {
  return new Promise((resolve, reject) =>
    neighborhoodResource
      .fetchNeighborhoodsByCity(idCity)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function getNeighborhoodById(id) {
  return new Promise((resolve, reject) =>
    neighborhoodResource
      .getNeighborhoodById(id)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function createNewNeighborhood(neighborhood) {
  return new Promise((resolve, reject) =>
    neighborhoodResource
      .createNewNeighborhood(neighborhood)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function updateNeighborhoodInformation(neighborhood) {
  return new Promise((resolve, reject) =>
    neighborhoodResource
      .updateNeighborhoodInformation(neighborhood)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}
export function removeNeighborhood(neighborhood) {
  return new Promise((resolve, reject) =>
    neighborhoodResource
      .removeNeighborhood(neighborhood)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}
